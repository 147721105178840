// x-variables.less


// Oversized (above --width-total-max)
@screen-oversized: ~"min-width: 1501px";
@screen-below-oversized: ~"max-width: 1500px";

// Large
@screen-large: ~"min-width: 1101px";
@screen-below-large: ~"max-width: 1100px";

// Medium
@screen-medium: ~"min-width: 751px";
@screen-below-medium: ~"max-width: 750px";



:root {
  // Colors
  --color-uio-red: #b60000;
  --color-uio-yellow: #FFFEA7;
  --color-uio-peach: #FFEBD9;
  --color-uio-blue: #3E31D6;
  --color-uio-blue-light: #E6ECFF;
  --color-nearly-black: #18191c;

  // Color neutral transparents (Primarily use these, not opaque)
  --color-neutral-tint-20: rgba(0,0,0, .7);
  --color-neutral-tint-25: rgba(0,0,0, .6); // Contrast 5+
  --color-neutral-tint-30: rgba(0,0,0, .5); // Contrast 4.0
  --color-neutral-tint-80: rgba(0,0,0, .2);
  --color-neutral-tint-90: rgba(0,0,0, .1);
  --color-neutral-tint-95: rgba(0,0,0, .05);

  // Color neutral opaque equivalents (Use if transparents not suitable)
  --color-neutral-tint-opaque-20: #4c4c4c;
  --color-neutral-tint-opaque-25: #666;
  --color-neutral-tint-opaque-30: #7f7f7f;
  --color-neutral-tint-opaque-80: #ccc;
  --color-neutral-tint-opaque-90: #e5e5e5;
  --color-neutral-tint-opaque-95: #f2f2f2;

  // Font-sizes
  --font-size-base: 1.8rem;
  --font-size-small: 1.6rem;
  --font-size-tiny: 1.4rem;
  --font-size-xtiny: 1.2rem;

  // Spacing
  --space: 40px;

  // Radius
  --radius: 5px;

  // Widths
  --width-total-max: 1500px;
  --width-soft-max: 1000px;
  --width-text-max: 700px;
}
