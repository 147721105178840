// x-consent.less

/*
- Imports groups/all consent styles.
- Defines variables and mixins necessary for X.
- Adds necessary styles for X.
*/

@import "vrtx/groups/all/src/resources/css/consent.less";



// Responsive breakpoints

// uio
// @responsiveConsentBreakpoint: ~"only screen and (max-width: 768px)";
// @tabletConsentBreakpoint: ~"only screen and (max-width: 1070px)";

// X
@responsiveConsentBreakpoint: ~"only screen and (max-width: 750px)";
@tabletConsentBreakpoint: ~"only screen and (max-width: 1100px)";



// Checkbox-style mixin
.checkbox-style() {
  margin-left: 30px;
  display: inline-block;
  position: relative;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  &:before {
    content: " ";
    height: 18px;
    width: 18px;
    background: transparent url("../images/checkbox-unchecked.svg") no-repeat center;
    background-size: 18px auto;
    margin-left: -30px;
    position: absolute;
    top: 6px;
    left: 0;
  }
}
.checkbox-style-checked() {
  &:before {
    background-image: url("../images/checkbox-checked.svg");
  }
}
.checkbox-style-disabled() {
  color: #666;
  &:hover {
    cursor: default;
    text-decoration: none;
  }
  &:before {
    opacity: 0.4;
  }
}



// Consent and consent-settings
.consent-overlay {
  z-index: 100; // Just below consent/-settings
}
.consent,
.consent-settings {
  z-index: 101; // Above all
}



// Buttons
.consent .consent-inner .consent-main-buttons,
.consent-settings .consent-settings-inner .consent-settings-main-buttons {
  display: grid;
  gap: 10px 20px;

  .button-large {
    width: auto;
    padding: 10px 15px;
    @media (@screen-medium) {
      min-width: 200px;
    }
    &::after {
      display: none;
    }
    &:hover {
      background-color: black;
    }
  }
}
.consent .consent-inner .consent-main-buttons {
  grid-template-columns: 1fr 1fr;
}
.consent-settings .consent-settings-inner .consent-settings-main-buttons {
  grid-template-columns: max-content max-content;
}



// Settings
.consent-settings .consent-settings-inner {
  ul {
    list-style: none;
    padding: 0;
  }
  .consent-settings-type {
    table {
      @media (@screen-below-medium) {
        * {
          font-size: 1.4rem;
        }
      }
      thead {
        background: none;
        th {
          white-space: nowrap;
        }
      }
      p {
        margin: 0;
      }
    }
  }
}