// x-timeline.less

/*
Needs x-timeline.js
*/

.vrtx-article-body .timeline,
.vrtx-frontpage-box.timeline {
  position: relative;
  padding-bottom: 60px; // Space for prev/next buttons

  // Prev/next buttons
  .prev,
  .next {
    position: absolute;
    right: 0;
    bottom: 0;
    background: url("../images/arrow.svg") no-repeat center;
    background-size: 20px;
    width: 40px;
    height: 40px;
    padding: 0;
  }
  .prev {
    transform: rotate(180deg);
    right: 50px;
  }
  // Box content
  .vrtx-box-content,
  .timeline-inner {
    display: flex;
    overflow: auto;

    // Remove scrollbar
    scrollbar-width: none; // For Firefox
    &::-webkit-scrollbar {
      display: none; // For WebKit browsers
    }

    // Container decoration
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 13px; // Align with item decoration
      border-bottom: 2px dashed;
    }
    // Each timeline item
    > div {
      position: relative;
      z-index: 1;
      background-color: var(--color-neutral-tint-opaque-95);
      border-radius: var(--radius);
      border-top-left-radius: 0;
      padding: 25px;
      margin-top: 70px; // Space for decoration

      // Item width control
      flex-shrink: 0;
      margin-right: 3%;

      // Show 3 + hint of next
      flex-basis: 29%;
      @media (@screen-below-large) {
        // Show 2 + hint of next
        flex-basis: 45%;
      }
      @media (@screen-below-medium) {
        // Show 1 + hint of next
        flex-basis: 90%;
      }
      // Item decoration
      &::before,
      &::after {
        content: "";
        position: absolute;
      }
      &::before {
        background-color: inherit;
        transform: skew(0deg, 45deg);
        width: 40px;
        height: 40px;
        left: 0;
        top: -10px;
        z-index: -1;
      }
      &::after {
        width: 24px;
        height: 24px;
        background-color: white;
        border: 2px solid;
        border-radius: 100%;
        left: 0;
        top: -70px;
      }
      p {
        margin-bottom: 15px;
      }
    }
  }
  // If no overflow, remove prev/next buttons
  &.no-overflow {
    padding-bottom: 0; // Remove button space
    .prev,
    .next {
      display: none;
    }
  }
}
// In colored row
.row-all-colored .vrtx-frontpage-box.timeline {
  .vrtx-box-content {
    > div {
      background-color: white;
      &::after {
        background-color: var(--color-neutral-tint-opaque-95);
      }
    }
  }
}