@galleryMaxWidthDesktop: 1000px;
@galleryHeightWithoutCaption: 425px;
@galleryMaxWidthFullScreenOfTotalWidth: 80;
@galleryRemainingWidthFullScreenOfTotalWidth: 20;
@galleryMaxWidthCaptionDesktop: 600px;
@galleryMaxWidthCaptionFullScreen: 1000px;
@galleryFullScreenBg: #000;
@galleryPrevNextWidthMobile: 33px;
@galleryPrevNextWidthDesktop: 43px;
@galleryListOnlyMaxWidth: 700px;

// added for compatibility in x1
@responsiveBreakpointPx: 750px;
@responsiveBreakpoint: ~"only screen and (max-width: @{responsiveBreakpointPx})";

// mixins
.transition (@seconds, @prop: all, @easing: ease-in-out) {
  -webkit-transition: @prop @seconds @easing;
  -moz-transition: @prop @seconds @easing;
  -o-transition: @prop @seconds @easing;
  transition: @prop @seconds @easing;
}

// Grid adjustments, need to be dragged out of .vrtx-article-body 690px (gallery is max 1000px)
.vrtx-image-gallery-include-outer-wrapper {
  width: 1000px;
  margin-left: -150px; // 700 + 150 + 150 = 1000px (cant be 200px)
}
#vrtx-frontpage .vrtx-image-gallery-include-outer-wrapper {
  width: auto;
  margin-left: 0;
}

.main .vrtx-image-gallery-include {
  max-width: @galleryMaxWidthDesktop;
  position: relative;
  overflow: hidden;
  margin: 40px 0;
  clear: both;
  text-align: left;

  &:only-child {
    max-width: @galleryListOnlyMaxWidth;
  }

  .vrtx-image-gallery-include-title {
    display: none;
  }

  .vrtx-image-gallery-include-container-pure-css { // Avoid CLS if doesn't have description
    height: @galleryHeightWithoutCaption + 51px; // image container is always 425px + the height with only "Show fullscreen" (you can't know the height of description beforehand)
  }

  .thumbs-prev-next-wrapper,
  .vrtx-image-gallery-include-container,
  .vrtx-image-gallery-include-container-nav {
    width: 100%;
    height: @galleryHeightWithoutCaption;
  }

  .thumbs-prev-next-wrapper,
  .vrtx-image-gallery-include-container-nav {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    flex-direction: row;

    .prev,
    .next,
    .thumbs-prev,
    .thumbs-next {
      height: 45px;
      width: 26px;
      display: block;
      z-index: 10;

      &.hidden-thumbs-direction {
        visibility: hidden;
      }
    }

    .prev,
    .thumbs-prev {
      background: url("../images/gallery/prev.svg") no-repeat 0 0;
      background-size: auto 100%;
      margin-right: auto;
    }
    .next,
    .thumbs-next {
      background: url("../images/gallery/next.svg") no-repeat 0 0;
      background-size: auto 100%;
    }
  }

  .vrtx-image-gallery-progress {
    padding: 5px 18px;
    border-radius: 8px;
    position: absolute;
    left: ~"calc(50% - 28px)"; // progress is 56px wide
    top: 90%;
    background: rgba(0,0,0,0.6);
    font-size: 1.5rem;
    line-height: 2.1rem;
    text-align: center;
    color: #fff;
    z-index: 5;
  }

  &.animation-off {
    .vrtx-image-gallery-image {
      -webkit-transition: none;
      -moz-transition: none;
      -o-transition: none;
      transition: none;
    }
  }

  .vrtx-image-gallery-image {
    margin: 0;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    .transition(.4s, all, ease-in-out);
    transform-origin: center;
    transform: translate3d(0,0,0);
    will-change: transform, opacity;
    opacity: 1;

    &:not(.active) {
      opacity: 0.5;
      z-index: 0;
      figcaption {
        visibility: hidden;
      }
    }
    &.active {
      z-index: 4;
      img {
        cursor: pointer;
        .transition(.4s, transform, ease-in-out);
        &:hover {
          transform: scale(1.1);
          will-change: transform;
        }
      }
    }

    &.active-prev-prev,
    &.active-next-next,
    &.active-next,
    &.active-prev {
      z-index: 3;
    }

    &.loop-around:not(.active-prev):not(.active-next):not(.active) {
      z-index: 0;
      opacity: 0;
      -webkit-transition: none;
      -moz-transition: none;
      -o-transition: none;
      transition: none;
    }

    .vrtx-image-gallery-image-container {
      height: @galleryHeightWithoutCaption;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      img {
        max-height: 100%;
        width: 100%;
        vertical-align: middle;
      }
    }

    figcaption {
      display: block;
      margin: 20px auto 0 auto;
      max-width: @galleryMaxWidthCaptionDesktop;
      font-size: 1.6rem;
      line-height: 1.5;

      p {
        margin: 10px 0 0 0;
      }
    }

    .vrtx-image-gallery-image-title {
      font-size: 1.8rem;
    }
  }

  .vrtx-image-gallery-loading-bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #FFF;
    z-index: 10;

    svg {
      margin-left: 50%;
      margin-top: 180px;
      height: 100px;
      width: 100px;
    }
    .loading-circle-bg,.loading-indicator {
    	fill:none;
    	stroke-linecap:bevel;
    	stroke-linejoin:round;
    }
    .loading-circle-bg {
    	stroke-width:2px;
    }
    .loading-indicator {
    	fill:none;
    	stroke:#888;
    	stroke-width:4px;
    }
  }
}

/*
 * Toggle modes links
 *
 */

.vrtx-image-gallery-toggle-modes-wrapper {
  text-align: right;
  max-width: @galleryMaxWidthDesktop;
  margin-bottom: -20px;
  margin-top: 20px;
}

.vrtx-image-gallery-show-fullscreen,
.vrtx-image-gallery-toggle-thumbnail-view {
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.vrtx-image-gallery-toggle-thumbnail-view {
  &:before {
    content: "";
    background: url("../images/gallery/thumbnail-view-open.svg") no-repeat 0 0;
    background-size: 100% auto;
    height: 15px;
    width: 15px;
    display: inline-block;
    position: relative;
    margin-right: 10px;
    top: 2px;
  }

  &.thumbnail-view-close {
    &:before {
      height: 10px;
      background-image: url("../images/gallery/thumbnail-view-close.svg");
      top: 0;
    }
  }
}

.vrtx-image-gallery-close-fullscreen,
.vrtx-image-gallery-toggle-thumbnail-view {
  font-size: 1.6rem;
  line-height: 2.1rem;
}

.vrtx-image-gallery-show-fullscreen-wrapper {
  margin-top: 20px;

  a {
    &:before {
      content: "";
      background: url("../images/gallery/fullscreen-open.svg") no-repeat 0 0;
      background-size: auto 100%;
      height: 24px;
      width: 24px;
      display: inline-block;
      position: relative;
      margin-right: 5px;
      top: 5px;
    }
  }
}

.vrtx-image-gallery-close-fullscreen {
  display: none;
  background: url("../images/gallery/fullscreen-close.svg") no-repeat 0 0;
  background-size: auto 100%;
  padding-left: 30px;
}

/*
 * Thumbnail view
 *
 */

.vrtx-image-gallery-include-thumbs-scroll-wrapper {
  display: none;
  position: relative;

  &.activate {
    > ul {
      position: absolute;
      top: 0;
      .transition(.4s, all, ease-in-out);
      transform-origin: center;
      will-change: transform;
      width: 100%;
      height: 100%;
    }
  }

  &:not(.activate) {
    .thumbs-prev-next-wrapper {
      display: none;
    }
  }

  > ul.active-thumbs-in-scroll {
    transform: translate3d(0,0,0);
  }
}


main ul.vrtx-image-gallery-include-thumbs {
  display: none;
  grid-gap: 5px;
  grid-template-columns: repeat(auto-fill, minmax(138px, 1fr));
  grid-template-rows: repeat(auto-fill, 138px);
  margin: 0;
  z-index: 1;
  padding-left: 0;

  li {
    &:before {
      display: none;
    }
    list-style: none;

    margin: 0;

    a, img {
      display: block;
    }

    a {
      width: auto;
      height: 138px;
      overflow: hidden;
    }
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      opacity: 0.8;
      margin: 0;
    }
  }

  .vrtx-full-image {
    display: none;
  }
}

main .vrtx-image-gallery-thumbnail-view {
  .vrtx-image-gallery-include-thumbs-scroll-wrapper {
    display: block;

    &.activate {
      ul.vrtx-image-gallery-include-thumbs {
        grid-auto-flow: column;
      }
    }
  }
  ul.vrtx-image-gallery-include-thumbs {
    display: grid;
  }
  .vrtx-image-gallery-include-container {
    display: none;
  }
}

// type=[list] - only show thumbnails
main .vrtx-image-gallery-include-title + ul.vrtx-image-gallery-include-thumbs {
  display: grid;
}

/*
 * Fullscreen mode
 *
 */

html.vrtx-image-gallery-fullscreen {
  overflow: hidden;

  // For specials: position fixed doesn't work inside transform
  &.special-page-config,
  &.special-page-config-header-minimal {
    .grid-container {
      transform: none !important;
      transition: none !important;
    }
  }

  #main .grid-container .vrtx-frontpage-box {
    z-index: auto;
  }

  .vrtx-image-gallery-toggle-modes-wrapper {
    position: absolute;
    top: 10px;
    right: 20px;
    z-index: 1;
  }
  #vrtx-frontpage .vrtx-image-gallery-toggle-modes-wrapper {
    margin-top: 20px;
  }
  .vrtx-image-gallery-include-thumbs-scroll-wrapper {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    margin-top: 90px;
  }
  .vrtx-image-gallery-toggle-thumbnail-view,
  .vrtx-image-gallery-close-fullscreen {
    color: #fff;

    &:focus,
    &:hover {
      color: #fff;
      text-decoration-color: #fff;
    }
  }
  .vrtx-image-gallery-toggle-thumbnail-view {
    margin-right: 20px;

    &:before {
      background-image: url("../images/gallery/thumbnail-view-open-white.svg");
    }
    &.thumbnail-view-close {
      &:before {
        background-image: url("../images/gallery/thumbnail-view-close-white.svg");
      }
    }
  }
  .vrtx-image-gallery-close-fullscreen {
    display: inline-block;
    max-width: none;
  }

  &,
  body {
    background: @galleryFullScreenBg;
  }
  #main .vrtx-image-gallery-include-container figure.vrtx-image-gallery-image .vrtx-image-gallery-image-description {
    &,
    a {
      color: #fff;
    }
    a {
      text-decoration-color: #fff;
    }
  }
  #vrtx-frontpage .main .vrtx-image-gallery-selected-fullscreen {
    width: 100% !important;
  }
  .main .vrtx-image-gallery-selected-fullscreen {
    overflow-y: scroll;
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100%;
    z-index: 11;
    margin: 0;
    margin-left: 0 !important;
    background: @galleryFullScreenBg;

    .vrtx-image-gallery-include {
      max-width: none;
      margin: 0;

      .vrtx-image-gallery-image {
        &.active {
          img {
            -webkit-transition: none;
            -moz-transition: none;
            -o-transition: none;
            transition: none;
            &:hover {
              transform: none;
            }
          }
        }
        .vrtx-image-gallery-image-container {
          height: auto;
        }
        figcaption {
          max-width: @galleryMaxWidthCaptionFullScreen;
          margin-bottom: 20px;

          .vrtx-image-gallery-show-fullscreen-wrapper {
            display: none;
          }
        }
        &:not(.active) {
          opacity: 0;
        }
      }
      .vrtx-image-gallery-include-container-nav {
        height: 100vh;
        width: ~"calc(@{galleryMaxWidthFullScreenOfTotalWidth}% + calc(calc(@{galleryPrevNextWidthDesktop} * 2) + 20px))";
        left: ~"calc(calc(@{galleryRemainingWidthFullScreenOfTotalWidth}vw - calc(calc(@{galleryPrevNextWidthDesktop} * 2) + 20px)) / 2)";

        .prev,
        .next {
          //height: 26px;
          width: @galleryPrevNextWidthDesktop;
          background-size: contain;
        }
        .prev {
          background-image: url("../images/arrow-white.svg");
          transform: rotate(180deg);
        }
        .next {
          background-image: url("../images/arrow-white.svg");
        }
        .vrtx-image-gallery-progress {
          top: 27px;
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .vrtx-image-gallery-include-outer-wrapper {
    margin-left: 0;
    width: 100%;
  }
}

@media @responsiveBreakpoint {
  #main .vrtx-image-gallery-include .vrtx-image-gallery-image:not(.active) {
    opacity: 0;
  }
  .main .vrtx-image-gallery-include .vrtx-image-gallery-image.active img:hover {
    transform: scale(1);
  }
  .vrtx-image-gallery-show-fullscreen-wrapper {
    display: none;
  }
  html.vrtx-image-gallery-fullscreen {
    .main .vrtx-image-gallery-selected-fullscreen {
      .vrtx-image-gallery-include {
        .vrtx-image-gallery-include-container-nav {
          width: ~"calc(@{galleryMaxWidthFullScreenOfTotalWidth}% + calc(calc(@{galleryPrevNextWidthMobile} * 2) + 15px))";
          left: ~"calc(calc(@{galleryRemainingWidthFullScreenOfTotalWidth}vw - calc(calc(@{galleryPrevNextWidthMobile} * 2) + 15px)) / 2)";

          .prev,
          .next {
            height: 20px;
            width: @galleryPrevNextWidthMobile;
          }
        }
      }
    }
  }
}
