// x-article.less

/*
Styles used for articles, events and other similar, article-ish pages
*/


// Byline
// On event page. Contains vrtx-time-and-place-container.
.vrtx-byline {
  margin-bottom: 40px;
}



// Introduction image

// Max-width to match h1 and introduction text
// Max-height to handle portrait format
// Table layout makes imagetext follow img width
.vrtx-introduction-image {
  display: table;
  width: auto !important; // Override inline-style
  max-width: var(--width-soft-max);
  margin-bottom: 50px;
  img {
    max-height: 700px; // Image will be full width until 10/7 ratio (1.42)
    margin: 0;
  }
  .vrtx-imagetext {
    display: table-caption;
    caption-side: bottom;
    // -webkit-hyphens: initial;
    // hyphens: initial;
    overflow-wrap: initial; // Needs this due to table layout. Too wide words unlikely here anyway.
  }
}



// Image text / caption
.vrtx-imagetext,
figcaption {
  font-size: var(--font-size-tiny);
  color: var(--color-neutral-tint-25);
  margin-top: 5px;
  p {
    margin: 0;
  }
}



// Article-body
.vrtx-article-body {
  max-width: var(--width-text-max);
  border-bottom: 1px solid;
  padding-bottom: 80px;
  margin-bottom: 30px;

  // Clear floats
  &::after {
    content: "";
    clear: both;
    display: block;
  }
  // General float clearing. assuming nothing should float next to these.
  hr,
  figure,
  table,
  video,
  iframe {
    clear: both;
  }
  figure,
  img,
  video {
    margin: 40px 0;
  }
  figure {
    img,
    video {
      margin: 0;
      width: 100%;
    }
  }
  hr {
    padding-top: 80px;
    border: none;
    border-bottom: 1px solid var(--color-neutral-tint-80);
    margin-bottom: 40px;
  }
  h2 {
    margin: 50px 0 20px;
  }
  // Iframe, mainly considering video.
  iframe {
    margin: 40px 0;

    // Podcast. Comes with container-div that handles spacing
    &[src*="captivate.fm"] {
      margin: 0;
    }
  }
  .vrtx-media-player iframe {
    margin-top: 0; // Due to &nbsp element in container. pending jira VTK-7708.
  }
  // Image-left/right
  .image-left,
  .image-right {
    width: 300px;
  }
  @media (@screen-below-medium) {
    .image-left,
    .image-right {
      max-width: 70vw;
      margin-right: auto;
      margin-left: auto;
    }
    img[align] {
      float: none; // Prevent deprecated attribute
    }
  }
  @media (@screen-medium) {
    .image-left,
    .image-right {
      clear: both;
      margin: 10px 40px 40px 40px;
    }
    .image-left {
      float: left;
      margin-left: 0;
    }
    .image-right {
      float: right;
      margin-right: 0;
    }
  }
  // Facts-container
  .vrtx-facts-container {
    background: var(--color-neutral-tint-95);
    font-size: var(--font-size-small);
    padding: 30px;
    margin: 40px 0;

    @media (@screen-large) {
      clear: both;
      &.vrtx-container-left,
      &.vrtx-container-right {
        margin: 10px 40px 40px 40px;
      }
      &.vrtx-container-left {
        float: left;
        width: 350px;
        margin-left: 0;
      }
      &.vrtx-container-right {
        float: right;
        width: 400px;
        margin-right: -100px;
      }
    }
    h2,
    h3 {
      font-size: 2.4rem;
      margin: 0;
      &:first-child {
        margin: 0 0 20px 0;
      }
    }
  }
}



// Page type specific
#vrtx-structured-article,
#vrtx-structured-event {

  // Content indent
  @media (@screen-large) {
    #vrtx-content {
      margin-left: 200px;
    }
  }
  // h1 and intro
  h1,
  .vrtx-introduction {
    max-width: var(--width-soft-max);
  }
  // Related content. Deprecated.
  // Placed after #vrtx-main-user, before .vrtx-date-info, unless set to "Vis i høyrekolonne"
  #vrtx-related-content {
    max-width: var(--width-text-max);
    margin-top: 80px;

    // Bottom separation
    border-bottom: 1px solid;
    padding-bottom: 80px;
    margin-bottom: 30px;
  }
  // Additional content. Deprecated right column.
  // Note #vrtx-main-content class vrtx-hide-additional-content-true/false
  #vrtx-additional-content {

    // Related content placed here if set to "Vis i høyrekolonne"
    #vrtx-related-content {

      // Revert bottom separation
      border-bottom: none;
      padding-bottom: 0;

      // Add top separation
      border-top: 1px solid;
      padding-top: 30px;
    }
  }
}



// Article meta elements
// Organizers, tags, publish date, authors etc
.vrtx-event-organizers {
  margin: 0;
  h2 {
    display: inline;
    font-size: inherit;
    &::after {
      content: ":";
    }
  }
}
.vrtx-tags {
  display: block;
  .title {
    font-weight: bold;
  }
}
.vrtx-authors {
  display: block;
  .vrtx-authors-prefix {
    font-weight: bold;
    &::after {
      content: ":";
    }
  }
}
.vrtx-date-info {
  .published-date-label {
    font-weight: bold;
    &::after {
      content: ":";
    }
  }
}



// Social components
.vrtx-social-components {
  margin-top: 25px;
  * {
    @media (@screen-medium) {
      display: inline-flex;
    }
  }
  ul {
    list-style: none;
    padding: 0;
  }
  a {
    display: inline-flex;
    align-items: center;
    margin: 0 20px 5px 0;
    text-decoration: none;
    &::before {
      content: "";
      flex-shrink: 0;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 100%;
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
    &:hover {
      text-decoration: underline;
    }
    &.vrtx-email-friend::before {
      background-image: url("../images/social/mail.svg");
    }
    &.facebook::before {
      background-image: url("../images/social/facebook.svg");
    }
    &.twitter::before {
      background-image: url("../images/social/x.svg");
    }
  }
}



// Comments
.vrtx-comments {
  background-color: var(--color-neutral-tint-95);
  padding: 20px;
  margin-top: 25px;
  max-width: var(--width-text-max);
  .button {
    padding: 5px 10px;
    &::after {
      display: none;
    }
  }
  .comments-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
  }
  .comments-header {
    margin-bottom: 40px;
    .header-href {
      .mixin-h3;
      display: block;
      margin-bottom: 10px;
    }
    #comments-header-feedHref {
      font-size: var(--font-size-small);
      color: var(--color-neutral-tint-25);
    }
  }
  .vrtx-comment {
    margin-bottom: 40px;
  }
  .comment-body {
    margin-bottom: 10px;
    * {
      margin: 0;
    }
  }
  .comment-info {
    > span {
      font-size: var(--font-size-small);
      color: var(--color-neutral-tint-25);
    }
    form {
      margin-top: 10px;
    }
  }
  .submit {
    input {
      margin-right: 5px;
      @media (@screen-below-medium) {
        display: block;
        margin-bottom: 5px;
      }
    }
  }
}
