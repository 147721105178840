// x-filters.less


// Filters-form
// Add page-ids as needed
#vrtx-uh-exchange-listing,
#vrtx-uh-person-listing {
  #filters-form {
    margin-bottom: 25px;
    fieldset {
      ul {
        list-style: none;
        padding: 0;
      }
      li {
        display: flex;
        align-items: baseline;
        width: fit-content;
        gap: 5px;
        input {
          flex-shrink: 0;
        }
        label {
          flex-grow: 1;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    // Text search filter
    #vrtx-filter-query {
      display: flex;
      gap: 10px;
      max-width: var(--width-text-max);
      margin-bottom: 25px;
      input {
        width: 100%;
      }
      button {
        flex-shrink: 0;
      }
    }
  }
  // Active filters
  .active-filters {
    &,
    * {
      display: inline-block;
    }
  }
  span.active-filters {
    margin-right: 5px;
    margin-bottom: 5px;
  }
  ul.active-filters {
    list-style: none;
    padding: 0;
    margin-right: 5px;
    margin-bottom: 0;
    li {
      margin: 0 5px 10px 0;
    }
    a {
      display: flex;
      gap: 10px;
      font-size: var(--font-size-small);
      line-height: 1.3;
      text-decoration: none;
      padding: 5px 15px;
      background-color: var(--color-neutral-tint-95);
      border-radius: var(--radius);
      &::after {
        content: "";
        flex-shrink: 0;
        width: 10px;
        background: url("../images/x-small.svg") no-repeat center;
        background-size: contain;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
  // Clear-filters button/link
  .clear-filters {
    display: inline-block;
    margin-bottom: 20px;
  }
}



// Frontpage row custom listing filter
// Needs x-filter-frontpage-rows.js
.vrtx-listing-filter-custom {
  list-style: none;
  padding: 0;
  li {
    margin-bottom: 10px;
    a {
      text-decoration: none;
      display: flex;
      align-items: center;
      gap: 10px;
      &::before {
        content: "";
        flex-shrink: 0;
        border: 1px solid;
        width: 18px;
        height: 18px;
      }
      &:hover {
        text-decoration: underline;
      }
      &[aria-checked="true"] {
        &::before {
          background: black url("../images/check-white.svg") no-repeat center;
        }
      }
    }
  }
  // Horizontal version
  &.vrtx-listing-filter-custom-horizontal {
    li {
      display: inline-block;
      margin-right: 20px;
    }
  }
}
// Filtered frontpage rows
.grid-container {
  &.inactive-row {
    display: none;
  }
}