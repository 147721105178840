// x-scroll-motion.less

/*
Adds motion to frontpage rows. Needs JS of same name.
Two classes added to row (not box): "scroll-motion" + motion-type, like "slide-from-right".
Not recommended for top row / rows that are above fold on load.

Motion-types:
- slide-from-right
- slide-from-left
- reveal-to-center
*/

body:not(.vrtx-mode-edit) {
  .grid-container.scroll-motion {
    @media (prefers-reduced-motion: no-preference) {
      overflow: hidden;

      // Motion type: Slide-from-right/left
      &.slide-from-right,
      &.slide-from-left {
        .vrtx-frontpage-box {
          transition: transform .75s ease-in-out;
          @media (@screen-below-medium) {
            transition-duration: .5s;
          }
        }
        &.entered-view {
          .vrtx-frontpage-box {
            transform: translateX(0);
          }
        }
      }
      &.slide-from-right {
        .vrtx-frontpage-box {
          transform: translateX(90vw); // Show a bit, to make sure img loads
        }
      }
      &.slide-from-left {
        .vrtx-frontpage-box {
          transform: translateX(-90vw);
        }
      }

      // Motion-type: Reveal-to-center
      // Not ideal for all content types. Best for media-wide and img/box/link-special
      &.reveal-to-center {
        position: relative;
        &::before {
          content: "";
          background-color: white; // Match row background
          position: absolute;
          z-index: 1;
          height: 100%;
          width: 100vw;
          top: 0;
          left: 0;
          right: 0;
          margin: 0 auto;
          transition: width 1s ease-in-out;
          @media (@screen-below-medium) {
            transition-duration: .5s;
          }
        }
        &.row-all-colored {
          &::before {
            // Match colored row background. Opaque needed.
            background-color: var(--color-neutral-tint-opaque-95);
          }
        }
        &.entered-view {
          &::before {
            width: 0;
          }
        }
      }
    }
  }
}

