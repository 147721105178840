// x-people.less


// vrtx-person-listing
// Note: there is both a list-variant and table variant of this, with the same class

// List variant
// ul.vrtx-person-listing {
// }

// Table variant
table.vrtx-person-listing {
  width: 100%;
  th,
  td {
    vertical-align: top;
    border: none;
  }
  th {
    font-weight: normal;
    white-space: nowrap;
  }
  thead {
    background: none;
  }
  tbody {
    tr:nth-child(odd) {
      background-color: var(--color-neutral-tint-95);
    }
    td {
      @media (@screen-large) {
        padding: 20px 10px;
      }
    }
    // Name cell (includes, image, name and title)
    .vrtx-person-listing-name {
      @media (@screen-large) {
        position: relative;
        padding-left: 150px; // Space for image
        height: 190px; // Works like min-height
      }
      .vrtx-image {
        position: absolute;
        border: 1px solid var(--color-neutral-tint-90);
        width: 100px;
        height: 150px;
        left: 10px;
        @media (@screen-large) {
          left: 20px;
        }
      }
      img {
        aspect-ratio: auto; // Container link forces ratio
        height: 100%;
        margin: 0;
      }
      // Name
      a:not(.vrtx-image) {
        font-weight: bold;
        @media (@screen-medium) {
          font-size: 2.4rem;
        }
      }
      // Job title
      span {
        display: block;
      }
    }
  }
  @media (@screen-below-large) {
    &,
    caption,
    tbody,
    tr,
    th,
    td {
      display: block;
    }
    thead {
      display: none;
    }
    th,
    td {
      padding: 0;
    }
    tbody {
      tr {
        position: relative;
        padding: 20px 10px 20px 130px; // Left space for image
        min-height: 190px;
      }
    }
  }
}



// vrtx-person page

/*
Styles simplified march 2024.
There are differences in markup for uh-sites (schools) and other sites,
yet many classes are similar, so consistent styling is difficult.
Templates need cleanup.
*/

// Page id
#vrtx-person {
  .vrtx-article-body {
    border: none;
    padding: 0;
    margin-bottom: 50px;
  }
}
.vrtx-person-contactinfo {
  .vrtx-label {
    display: block;
    font-weight: bold;
  }
}
.vrtx-person-contact-info-line {
  margin-bottom: 15px;
}
