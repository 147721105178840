// x-media-wide.less

/*
Styles for:
- Frontpage row with full-width image or background video.
- Frontpage row pulled to page-top, behind header.

Needs x-media-wide.js
*/


// Frontpage row with full-width image
.grid-container.media-wide {
  margin-bottom: calc(2*var(--space));
  background: none;
  padding: 0;
  .row {
    position: relative;
    padding: 0;
    max-width: none;
  }
  img,
  video, // Included for possible future support.
  .vrtx-frontpage-box {
    @media (@screen-large) {
      min-height: 500px;
    }
  }
  img,
  video {
    margin: 0;
    width: 100%;
    object-fit: cover;
    @media (@screen-large) {
      height: 70vh;
    }
    @media (@screen-below-large) {
      aspect-ratio: 3/2; // Added 27 may 2024 by Kjetil to improve cls
    }
  }
  .vrtx-frontpage-box {
    @media (@screen-large) {
      background-color: #eee;
    }
  }
  .vrtx-box-content {
    background-color: white;
    @media (@screen-large) {
      position: absolute;
      bottom: 50px;
      left: 50%;
      width: 500px;
      padding: 30px;
    }
    @media (@screen-below-large) and (@screen-medium) {
      padding: 30px 50px 0;
    }
    @media (@screen-below-medium) {
      padding: 30px 15px 0;
    }
    h2,
    p {
      margin-bottom: 15px;
    }
    h2 {
      font-size: 3rem;
    }
  }
}



// Frontpage row with full-width background video
.grid-container.background-video {
  background: none;
  padding: 0;
  .row {
    position: relative;
    padding: 0;
    max-width: none;
  }
  video,
  .vrtx-frontpage-box {
    @media (@screen-large) {
      min-height: 500px;
    }
  }
  video {
    width: 100%;
    object-fit: cover;
    @media (@screen-large) {
      height: 70vh;
    }
  }
  .vrtx-frontpage-box {
    @media (@screen-large) {
      background-color: #eee;
    }
  }
  .vrtx-box-content {
    > *:not(div) {
      // Added 18 april 2024 by Kjetil
      // Remove any stray elements outside of video and text-box divs, such as empty p-tags
      display: none;
    }
  }
  // Text-box div added manually, with heading and text inside
  .text-box {
    background-color: white;
    @media (@screen-large) {
      position: absolute;
      bottom: 50px;
      left: 50%;
      width: 500px;
      padding: 30px;
    }
    @media (@screen-below-large) and (@screen-medium) {
      padding: 30px 50px 0;
    }
    @media (@screen-below-medium) {
      padding: 20px 15px 0;
    }
    h2,
    p {
      margin-bottom: 15px;
    }
    h2 {
      font-size: 3rem;
    }
  }
}



// Background-video component
.vrtx-background-video {
  position: relative;

  // Visually disabled by default to require locally enabling
  // Set this ::before element to display: none in local styles to enable
  &::before {
    content: "Background-video not locally enabled.";
    background-color: yellow;
    padding: 10px;
    position: absolute;
    top: 50%;
  }
  // Default almost invisible to require locally enabling
  // Set video opacity to 1 in local styles to enable.
  video {
    opacity: .1;
  }
  // Pause-button
  .pause-video {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 80px;
    height: 80px;
    background: white url("../images/pause.svg") no-repeat center;
    background-size: 30px;
    padding: 0;
    border: none;
    border-radius: 0;
    opacity: 0; // Invisible unless hover/focus
    transition: opacity 1s;
    &.paused {
      background-image: url("../images/play.svg");
    }
  }
  // Hover on container or focus on button -> show button
  &:hover .pause-video,
  .pause-video:focus-visible {
    opacity: 1;
  }
}
// Preview/edit mode: disable vh units
body.vrtx-mode-edit,
body.vrtx-mode-preview {
  .grid-container {
    &.media-wide,
    &.background-video {
      img,
      video {
        height: auto;
      }
    }
  }
}



// Page-top class
// Used on frontpage row for background-video and wide-media to pull row to top of page, behind header.
.grid-container.page-top {
  // Subtract header height for overlap
  margin-top: -130px;
  @media (@screen-below-medium) {
    margin-top: -80px;
  }
}
// When menu or search is open, don't overlap
body.header-search-visible,
body.sidebar-menu-wrapper-visible {
  .grid-container.page-top {
    margin-top: 0;
  }
}
// "has-page-top-row" class added to body by js when detecting row page-top class.
body.has-page-top-row {
  // Breadcrumbs and h1 not supported with this layout
  #breadcrumbs,
  h1 {
    .invisible;
  }
  // Add backgrounds to header-tools items and adjust spacing
  #header-tools {
    @media (@screen-below-medium) {
      column-gap: 10px;
    }
    #header-language,
    .header-search-expand,
    .sidebar-menu-toggle {
      background-color: white;
      border-radius: 0;
      min-height: 36px;
      padding: 0 5px;
    }
  }
}
// In vortex edit-mode
body.vrtx-mode-edit {
  .grid-container.page-top {
    margin-top: 0; // Don't overlap header
  }
  &.has-page-top-row {
    h1 {
      position: static; // Revert from invisible (absolute positioned outside viewport).
    }
  }
}
