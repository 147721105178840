// x-publications.less


#vrtx-publications-wrapper {
  max-width: var(--width-soft-max);
  h2 {
    margin: 0;
  }
}
// Tabs (contains both selectors and publication lists)
#vrtx-publication-tabs {
  ul {
    list-style: none;
    padding: 0;
  }
  // Selector links
  > ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    padding: 15px 0;
    margin-bottom: 10px;
    @media (@screen-large) {
      position: sticky;
      top: 0;
      background-color: white;
    }
    a {
      display: block;
      border: 1px solid;
      border-radius: var(--radius);
      padding: 5px 15px;
      text-decoration: none;
      &:focus {
        outline-offset: 4px;
      }
      &:hover {
        background-color: var(--color-neutral-tint-95);
      }
      &[aria-expanded=true] {
        background-color: black;
        border-color: transparent;
        color: white;
      }
    }
  }
}
// Publication list container
div[id*=vrtx-publication-tab-] {
  @media (@screen-large) {
    // Add space to top to prevent overlap with selectors when they are sticky.
    // Doesn't need to be exact. Better if too high.
    // For exact match, use height of selector ul.
    margin-top: -80px;
    padding-top: 80px;
  }
}
// Class added by js to identify script
// If using script, control display of list containers
.using-publication-tabs-js {
  div[id*=vrtx-publication-tab-] {
    outline: none; // Prevent Safari focus indicator
    display: none;
    &.selected {
      display: block;
    }
  }
}
// Publication list
ul.vrtx-external-publications {
  li {
    margin-bottom: 25px;
  }
  .vrtx-title {
    display: block;
    font-weight: bold;
  }
}
// Summary container
.vrtx-publication-summary {
  display: block; // Temp. until html is fixed in Vortex. Is illegally a span.
  // Link to show summary. Note: has same class as parent
  a {
    display: flex;
    align-items: center;
    gap: 10px;
    width: fit-content;
    margin-top: 5px;
    border: 1px solid;
    border-radius: var(--radius);
    padding: 5px 15px;
    text-decoration: none;
    &::before {
      content: "";
      flex-shrink: 0;
      background: url("../images/chevron.svg") no-repeat center;
      background-size: contain;
      height: 1em;
      width: 8px;
      transition: all .3s;
    }
    &[aria-expanded=true] {
      &::before {
        transform: rotate(90deg);
      }
    }
    &:focus {
      outline-offset: 4px;
    }
  }
  // The summary (default hidden)
  // Is set to style="display: none".
  // When link clicked, style is removed, replaced by class.
  p {
    margin: 10px 0 0 0;
    display: none;
    &.open {
      display: block; // Js-controlled
    }
  }
}
