// x-admin-dropdown.less


// Admin-dropdown (used in header and footer)
#head-admin-menu,
#footer-wrapper {
  .vrtx-dropdown-link {
    &::after {
      content: "";
      padding-right: 20px;
      background: url("../images/arrow-triangle.svg") no-repeat right center;
      background-size: 11px auto;
    }
  }
  .vrtx-dropdown-wrapper {
    &:not(.open) {
      display: none; // Js-controlled
    }
    margin-top: 5px;
    background: white;
    color: black;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .2);
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
    li:not(:last-child) {
      border-bottom: 1px solid var(--color-neutral-tint-90);
    }
    a,
    button {
      padding: 10px 15px;
    }
    a {
      display: block;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    button {
      border: none;
      border-radius: 0;
      width: 100%;
      text-align: left;
      &:hover {
        background-color: transparent;
        text-decoration: underline;
      }
    }
  }
  // Log-out link
  a.vrtx-login-manage-logout {
    display: none; // Duplicate
  }
}



// Head version
#head-admin-menu-wrapper {
  background: var(--color-neutral-tint-95);
  border-bottom: 1px solid var(--color-neutral-tint-90);
}
#head-admin-menu {
  display: flex;
  justify-content: end;
  font-size: var(--font-size-tiny);
  padding-top: 5px;
  padding-bottom: 5px;

  // Dropdown-link
  .vrtx-dropdown-link {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .vrtx-dropdown-component {
    position: relative;
    z-index: 11; // Above header
  }
  .vrtx-dropdown-wrapper {
    position: absolute;
    right: 0;
    width: 250px;
  }
}



// Footer version
#footer-wrapper {
  .vrtx-dropdown-component {
    min-width: 250px;
  }
  .vrtx-dropdown-link {
    &::after {
      border-left: 1px solid;
      margin-left: 12px;
      background-image: url("../images/arrow-triangle-white.svg");
    }
  }
  .vrtx-dropdown-wrapper {
    width: fit-content;
  }
  // Log-out link
  .vrtx-login-manage-logout {
    display: none; // Is duplicate
  }
}



// When in admin preview and edit frontpage mode, hide dropdown
.vrtx-mode-preview,
.vrtx-mode-edit {
  #head-admin-menu-wrapper,
  #footer-wrapper .vrtx-dropdown-component {
    display: none;
  }
}
