// x-scroll-column-feed.less

/*
Enables horizontal scroll for frontpage feeds and events. Needs script of same name.
If max items in feed matches number of columns, scroll functionality will only be present on small screen.

Classes:
- scroll-column-feed: Three column default
- scroll-column-feed-four
- scroll-column-feed-wide: Three column. Goes outside content-width. Only for special cases.
*/

.vrtx-frontpage-box {
  &[class*="scroll-column-feed"] {
    .vrtx-box-content {
      overflow: auto;

      // Remove scrollbar
      scrollbar-width: none; // Firefox
      &::-webkit-scrollbar {
        display: none; // WebKit
      }
    }

    .vrtx-feed .items,
    .vrtx-event-component {
      display: flex;

      > * {
        flex-shrink: 0;

        // Default three columns
        @media (@screen-large) {
          // Show 3 + hint of next
          flex-basis: calc(32% - var(--space));
        }
        @media (@screen-below-large) {
          // Show 2 + hint of next
          flex-basis: calc(48% - var(--space));
        }
        @media (@screen-below-medium) {
          // Show 1 + hint of next
          flex-basis: calc(95% - var(--space));
        }
      }
      // Limited. JS-detected. 3 items in feed = no overflow on desktop.
      &.limited {
        > * {
          flex-grow: 1;
        }
      }
    }
  }
  // Four-column feed
  &.scroll-column-feed-four {
    .vrtx-feed .items,
    .vrtx-event-component {
      > * {
        @media (@screen-large) {
          flex-basis: calc(24% - var(--space));
        }
      }
    }
  }
  // Wide version. Goes outside content-width.
  &.scroll-column-feed-wide {
    .vrtx-feed .items,
    .vrtx-event-component,
    .vrtx-more {
      // Align with content left pos
      padding-left: calc((100vw - 1400px) / 2);

      @media (@screen-below-oversized) {
        padding-left: 50px;
      }
      @media (@screen-below-medium) {
        padding-left: 15px;
      }
      > * {
        // Use fixed px width for oversized screen width
        @media (@screen-oversized) {
          flex-basis: 420px;
        }
      }
    }
  }
}

// Parent row
.row:has([class*="scroll-column-feed"]) {

  // Heading + prev/next button container (js-generated)
  .heading-prev-next {
    display: flex;
    align-items: end; // Keep buttons aligned with top of feed if h2 breaks
    gap: 10px;
    margin-bottom: 25px;
  }
  h2 {
    margin: 0;
  }
  .prev,
  .next {
    flex-shrink: 0;
    background: url("../images/arrow.svg") no-repeat center;
    background-size: 20px;
    width: 40px;
    height: 40px;
    padding: 0;
  }
  .prev {
    transform: rotate(180deg);
    margin-left: auto;
  }
  // If limited, remove prev/next buttons
  &:has(.limited) {
    @media (@screen-large) {
      .prev,
      .next {
        display: none;
      }
    }
  }
}

// Wide version parent row
.row:has(.scroll-column-feed-wide) {

  // Remove viewport-properties to use full width
  padding: 0;
  margin: 0;
  max-width: none;

  .heading-prev-next {
    .mixin-page-wrapper; // Add viewport-properties to limit to content width
  }
}

