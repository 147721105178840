// x-crisis-banner.less

/*
UiO crisis-banner
*/

@crisis-banner-bg: #FF9090;
@crisis-banner-info-bg: #B4D7FF;

.grid-container {
  &.uio-info-message,
  &.crisis-banner {
    position: relative;
    margin: 0;
    line-height: 0;
    font-size: 0;
    background-color: @crisis-banner-bg;
    color: #000;

    b,
    strong {
      font-size: 1.8rem;
      line-height: 1.5;
      font-weight: 400;
      padding: 45px 200px 45px 264px; // left: 200px + 44px + 20px
      display: block;
      position: relative;

      &:before {
        background: transparent url("../images/crisis-banner.svg") no-repeat 0 0;
        content: "";
        width: 44px;
        height: 38px;
        margin-right: 20px;
        margin-left: -64px;
        display: inline-block;
        position: absolute;
        top: 38px;
      }

      @media (@screen-below-large) {
        padding: 25px 0 25px 64px;

        &:before {
          top: 17px;
        }
      }

      a {
        color: inherit;
      }
    }

    // Less critical info message
    &.info {
      background-color: @crisis-banner-info-bg;

      b,
      strong {
        padding: 26px 200px 26px 260px; // left: 200px + 40px + 20px

        &:before {
          background: transparent url("../images/crisis-banner-info.svg") no-repeat 0 0;
          width: 40px;
          height: 40px;
          margin-left: -60px;
          top: 18px;
        }

        @media (@screen-below-large) {
          padding: 26px 0 26px 60px;
        }
      }
    }
  }
}